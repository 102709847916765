<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import axios from 'axios';

import Multiselect from "vue-multiselect";
import InputTag from 'vue-input-tag';
import vue2Dropzone from "vue2-dropzone";
import {saveAs} from 'file-saver';

import {
  required,
  email,
  minLength,
  //sameAs,
  maxLength,
  minValue,
  maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Coach registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, InputTag, vueDropzone: vue2Dropzone },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Coach registration",
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Coachs",
          href: "/management/coaches"
        },
        {
          text: "Coach",
          active: true
        }
      ],

      groups:               [],
      pools:                [],
      selectedGroups:       [],
      selectedPools:        [],
      server:               "",
      validToken:           true,

      form: {
        id_pools:           [],
        id_groups:          [],
        de_gender:          "",
        nm_coach:           "",
        nm_lastName:        "",
        num_age:            0,
        num_phoneNumber:    "",
        de_email:           "",
        dt_birthday:        "",
        de_specialistOn:    [],
        url_photograph:     "",
      },
      dropzoneOptions: {
        url: window.serverPath+'dist/mvw/controllers/coaches.php',
        thumbnailWidth: 100,
        maxFilesize: 2.5,
        headers: { "My-Awesome-Header": "header value" },
        multipleUpload: false,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function() {
          this.on("maxfilesexceeded", file => {
            this.removeFile(file);
            Swal.fire('Ooops!!!','<p><b>You can only upload a single template file.</p>','error');
          });
        }
      },
      submitted: false,
      options:{
        format: 'YYYY-MM-DD',
      }
    };
  },
  validations: {
    form: {
      nm_coach:             { minLength: minLength(2), maxLength: maxLength(100) },
      nm_lastName:          { minLength: minLength(2), maxLength: maxLength(100) },
      num_age:              { minValue:minValue(18), maxValue:maxValue(100) },
      num_phoneNumber:      { required },
      de_email:             { required, email },
      de_gender:            { required }
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.server = window.serverPath+'dist';

    this.getPools();

    if( this.$route.params.id ){
      this.getCoachByID();
    }else{
      //this.getCompanies();
    }
  },
  methods: {
    async getCoachByID(){
      Swal.fire({
        title: "Loading employee data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/coaches.php',
        {
          params: {
            request: 'getCoachByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.form.de_gender         = response.data.de_gender;
          this.form.nm_coach          = response.data.nm_coach;
          this.form.nm_lastName       = response.data.nm_lastName;
          this.form.num_age           = response.data.num_age;
          this.form.num_phoneNumber   = response.data.num_phoneNumber;
          this.form.de_email          = response.data.de_email;
          this.form.dt_birthday       = response.data.dt_birthday+" 00:00:00Z";
          this.form.id_pools          = response.data.id_pools;
          this.form.id_groups         = response.data.id_groups;
          this.form.de_specialistOn   = response.data.de_specialistOn;
          this.form.url_photograph    = response.data.url_photograph;

          this.selectedPools          = response.data.id_pools;
          this.selectedGroups         = response.data.id_groups;

          this.getScheduleByPoolID();

          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    async getPools(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/pools.php',
        {
          params: {
            request: 'getPoolsForMultipleSelect',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if(response.data == 'Invalid token'){
            Swal.close();
            this.validToken = false;
          }else{
            this.pools = response.data;
            this.load++;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load pools data! Please load this module again.", "error");
        }
      });
    },

    async getScheduleByPoolID(){
      let app = this;

      if(!this.$route.params.id){
        this.$bvToast.toast(
          'Loading pool(s) schedules.',
          {
            title: 'Loading...',
            variant: 'info',
            solid: true
          }
        );
      }

      axios
      .post(window.serverPath+'dist/mvw/controllers/schedules.php', {
        request: 'getScheduleByPoolID',
        data: {
          pools:  app.selectedPools,
          id:     this.user.user.id_user,
        },
        token:  this.user.token,
      })
      .then(response => {
        if( response.status == 200 ){
          if(response.data.length > 0){
            this.groups = response.data;
            this.load++;

            if(this.$route.params.id){
              this.groups.forEach(function(group){
                if(app.form.id_group == group.id_group){
                  app.selectedGroup = group;
                }
              });
            }else{
              this.$bvToast.toast(
                'Schedules successfully loaded.',
                {
                  title: 'Done',
                  variant: 'success',
                  solid: true
                }
              );
            }
          }else{
            this.$bvToast.toast(
              'No groups found associated to the selected pool(s), please try again or try with another pool(s).',
              {
                title: 'Done',
                variant: 'warning',
                solid: true
              }
            );
          }
        }else{
          this.$bvToast.toast(
            "Ooops!", "Seems like some error ocurr, please try again.",
            {
              title: 'Loading error',
              variant: 'error',
              solid: true
            }
          );
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    saveCoach(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_coach = this.$route.params.id;
        }

        this.form.id_pools  = this.selectedPools;
        this.form.id_groups = this.selectedGroups;

        Swal.fire({
          title: "Saving coach data.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if(this.$refs.myVueDropzone.dropzone.files.length > 0 ){
          this.$refs.myVueDropzone.processQueue();
        }else{
          axios.post(window.serverPath+'dist/mvw/controllers/coaches.php', {
            request: 'saveCoach',
            data: this.form,
            token: this.user.token,
          })
          .then(function (response) {
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "Your coach is correct "+action+"!", "success")
            .then(function(response){
              if(response.isConfirmed){
                app.$router.push({ name: 'coaches' });
              }
            });
          })
          .catch(function (error) {
            Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
          });
        }
      }else{
        console.log
      }
    },

    sendingFile(file, xhr, formData){
      let app = this;
      
      file;
      xhr;
      formData;

      //Appending the request, file and data to the formData object to be send all in a single request.
      formData.append('request',  'saveCoach');
      formData.append('token', this.user.token);
      formData.append('data',     JSON.stringify(app.form) );
    },
    uploadCompleted(response){
      let app = this;
      
      console.log(response);
      if(response.status != "error"){
        Swal.close();
        Swal.fire("Good job!", "The team is correct saved!", "success")
        .then(function(response){
          if(response.isConfirmed){
            app.$router.push({ name: 'teams' });
          }
        });
      }else{
        Swal.close();
        Swal.fire("Ooops!", "There was an error when trying to save this team data. Please try again.", "error");
      }
    },

    downloadTicket(){
      //let app = this;
      axios
      .get(window.serverPath+'dist'+this.form.url_photograph, {responseType: 'blob'})
      .then(response => {
          saveAs(response.data);
      })
    },

    openTicket(){
      window.open(window.serverPath+'dist'+this.form.url_photograph);
    },

    consoleThis(){
      console.log(this.selectedPools);
    },

    removeSchedules(removed){
      if(this.selectedGroups.length > 0){
        this.selectedGroups = this.selectedGroups.filter(group => group.id_pool != removed.id_pool);
      }
      console.log(this.selectedGroups);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveCoach">
              <div class="row">
                <div class="col-sm-4">
                  <h4 class="card-title">Personal Data</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_coach">Coach name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_coach.$error }"
                          name="nm_coach"
                          id="nm_coach"
                          v-model="form.nm_coach"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_coach.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_coach.required">Coach name is required.</span>
                          <span v-if="!$v.form.nm_coach.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_coach.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="nm_lastName">Coach last name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_lastName.$error }"
                          name="nm_lastName"
                          id="nm_lastName"
                          v-model="form.nm_lastName"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_lastName.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_lastName.required">Coach last name is required.</span>
                          <span v-if="!$v.form.nm_lastName.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_lastName.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="num_age">Coach age</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.num_age.$error }"
                          name="num_age"
                          id="num_age"
                          v-model="form.num_age"
                          value=""
                        />
                        <div v-if="submitted && $v.form.num_age.$error" class="invalid-feedback">
                          <span v-if="!$v.form.num_age.required">Coach age is required.</span>
                          <span v-if="!$v.form.num_age.minValue">This minimum age is invalid. It should be between 18 and 100 years old.</span>
                          <span v-if="!$v.form.num_age.maxValue">This maximum age is invalid. It should be between 18 and 100 years old.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_gender">Gender</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_gender.$error }"
                          name="de_gender"
                          id="de_gender"
                          v-model="form.de_gender"
                        >
                          <option value="">SELECT EMPLOYEE GENDER</option>
                          <option value="FEMALE">FEMALE</option>
                          <option value="MALE">MALE</option>
                          <option value="NONE BINARY">NONE BINARY</option>
                        </select>
                        <div v-if="submitted && $v.form.de_gender.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_gender.required">Gender is required.</span>
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label for="dt_birthday">Coach Birthday</label>
                        <br />
                        <date-picker
                          v-model="form.dt_birthday"
                          :first-day-of-week="1"
                          lang="en"
                          name="dt_birthday"
                          id="dt_birthday"
                          valueType="format"
                          format="YYYY-MM-DD"
                          :value="form.dt_birthday"
                        >
                        </date-picker>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <h4 class="card-title">Other coach data</h4>
                  <div class="form-group">
                    <label for="num_phoneNumber">Phone number</label>
                    <input
                      v-mask="'+(##) ###-###-####'"
                      type="text"
                      class="form-control"
                      name="num_phoneNumber"
                      id="num_phoneNumber"
                      v-model="form.num_phoneNumber"
                    />
                    <div v-if="submitted && $v.form.num_phoneNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_phoneNumber.required">Phone number is required.</span>
                    </div>
                    <!--<span class="text-muted">e.g "(xx) xxxx-xxxx"</span>-->
                  </div>

                  <div class="form-group">
                    <label for="de_email">Coach email</label>
                    <input
                      type="email"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_email.$error }"
                      name="de_email"
                      id="de_email"
                      v-model="form.de_email"
                    >
                    <div v-if="submitted && $v.form.de_email.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_email.required">Coach email is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_pools">Pools</label>
                    <multiselect
                      id="id_pools"
                      v-model="selectedPools"
                      :options="pools"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="SELECT POOL(S)"
                      label="label"
                      track-by="label"
                      :preselect-first="false"
                      @select="getScheduleByPoolID"
                      @remove="removeSchedules"
                    ></multiselect>
                  </div>

                  <!--
                  <div class="form-group">
                    <label for="id_pools">Pools</label>
                    <select
                      multiple
                      class="form-control"
                      name="id_pools"
                      id="id_pools"
                      v-model="selectedPools"
                      size="3"
                      @change="getScheduleByPoolID"
                    >
                      <option v-for="(pool, index) in pools" :key="index" :value="pool">{{pool.nm_pool}}</option>
                    </select>
                  </div>
                  -->

                  <div class="form-group">
                    <label for="id_groups">Groups</label>
                    <multiselect
                      id="id_groups"
                      v-model="selectedGroups"
                      :options="groups"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="SELECT GROUP(S)"
                      label="label"
                      track-by="label"
                      :preselect-first="false"
                    ></multiselect>
                  </div>

                  <!--
                  <div class="form-group">
                    <label for="id_groups">Groups</label>
                    <select
                      multiple
                      class="form-control"
                      name="id_groups"
                      id="id_groups"
                      v-model="form.id_groups"
                      style="font-size: 10px;"
                    >
                      <option v-for="(group, index) in groups" :key="index" :value="group">{{group.schedule}}</option>
                    </select>
                  </div>
                  <b-button v-b-toggle.sidebar-right>Toggle Sidebar</b-button>
                  <b-sidebar id="sidebar-right" title="Sidebar" bg-variant="white" right backdrop shadow>
                    <div class="p-2">
                      <p>Please choose one or more groups from the next list:</p>
                      <div class="form-group">
                        <b-form-checkbox v-for="(group, index) in groups" :key="index" v-model="selectedGroups" class="ml-2" :value="group.id_group" style="font-size: 10px;">
                          <label>{{group.schedule}}</label>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </b-sidebar>
                  -->
                </div>

                <div class="col-sm-4">
                  <h4 class="card-title">Upload coach picture</h4>
                  <div class="form-group">
                    <button v-if="form.url_photograph != ''" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      Download photograph
                      <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                    </button>
                    <button v-if="form.url_photograph != ''" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" v-b-modal.modal-member>
                      View photograph
                      <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                    </button>

                    <b-modal no-close-on-backdrop
                      id="modal-member"
                      title="Member photograph"
                      title-class="font-18"
                      hide-footer
                    >
                      <img :src="server+form.url_photograph" width="100%" />
                    </b-modal>

                    <label for="dropzone" class="mb-5">&nbsp;</label>
                    <vue-dropzone
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      :uploadOnDrop="false"
                      @vdropzone-sending="sendingFile"
                      @vdropzone-complete="uploadCompleted"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Click to search or Drop in here the photograph</h4>
                      </div>
                    </vue-dropzone>
                    
                    
                  </div>

                  <div class="form-group">
                    <label for="de_specialistOn">Specialist on</label>
                    <input-tag
                      name="de_specialistOn"
                      id="de_specialistOn"
                      v-model="form.de_specialistOn"
                      :limit="10"
                      placeholder="10 feats separated by comma."
                      class="form-control"
                      height="10"
                    >
                    </input-tag>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE COACH</button>
                    <router-link to="/coaches" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .vue-input-tag-wrapper {
    height: auto !important;
  }

  .vue-input-tag-wrapper .input-tag {
    background-color: #5664d2 !important;
    border: 1px solid #5664d2 !important;
    color: #fff !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff !important;
  }
</style>